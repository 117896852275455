import { getItemIdFromPath, isItemPage, isPriceResultsPage } from '@/utils/urls';
import { getItemSummary } from '@/redux/modules/item/summary/itemSummary.selectors';
import { getSlugItemId } from '@/redux/modules/priceResults/priceResults.selectors';
import { GlobalState } from '@/redux/store';

/**
 * isItemPage404 - returns true if item or price-result page
 * has no data to return
 * @param path Current URL path
 * @param state Current redux store state
 * @returns boolean Whether the page should direct to the 404 page
 */
export const isItemPage404 = (path: string, state: GlobalState) => {
    let is404ItemPage = false;
    if (isItemPage(path)) {
        const itemId = getItemIdFromPath(path);
        const itemSummary = getItemSummary(state, itemId);
        const { catalogId, isDeleted } = itemSummary;
        if (!catalogId || isDeleted) {
            is404ItemPage = true;
        }
    }

    if (isPriceResultsPage(path)) {
        const slugItemId = getSlugItemId(state);
        if (!slugItemId) {
            is404ItemPage = true;
        }
    }
    return is404ItemPage;
};
