import { accountSettingsPageData } from '@/pages/AccountSettings/helpers/accountSettingsPageData';
import { DataLoaderParams } from './types/DataLoaderParams';
import { Location, RouteObject, useRoutes } from 'react-router-dom';
import aboutUsLoadData from './pages/AboutUs/helpers/aboutUsPageData';
import auctionCalendarLoadData from './pages/AuctionCalendar/helpers/auctionCalendarPageData';
import auctionsNearMePageData from '@/pages/AuctionsNearMe/helpers/auctionsNearMePageData';
import auctionVideoLoadData from './pages/AuctionVideo/helpers/auctionVideoPageData';
import biddingConsoleLoadData from './pages/BiddingConsole/helpers/biddingConsolePageData';
import catalogLoadData from './pages/Catalog/helpers/catalogPageData';
import catalogTermsLoadData from './pages/CatalogTerms/helpers/catalogTermsPageData';
import categoryLoadData from './pages/Search/CategorySearch/helpers/categorySearchData';
import collectionLoadData from './pages/CuratedCollection/helpers/curatedCollectionPageData';
import consignmentsLoadData from './pages/Consignments/helpers/consignmentsPageData';
import directoryLoadData from './pages/SellerDirectory/helpers/sellerDirectoryPageData';
import discoverCategoriesLoadData from './pages/DiscoverCategories/helpers/discoverCategoriesPageData';
import discoverLoadData from './pages/Discover/helpers/discoverPageData';
import featuredCatalogsLoadData from './pages/FeaturedCatalogs/helpers/featuredCatalogsPageData';
import homeLoadData from './pages/Home/helpers/homePageData';
import imageCarouselPopoutPageData from './pages/ImageCarouselPopout/helpers/imageCarouselPopoutPageData';
import itemLoadData from './pages/Item/helpers/itemPageData';
import loadable from '@loadable/component';
import messagesPageData from './pages/Messages/helpers/messagesPageData';
import messengerPageData from './pages/Messages/helpers/messengerPageData';
import myBidsLoadData from './pages/MyItems/MyBids/helpers/myBidsPageData';
import myFavoritesLoadData from './pages/MyItems/MyFavorites/helpers/myFavoritesPageData';
import priceResultLoadData from './pages/Item/helpers/priceResultPageData';
import ratingsLoadData from './pages/Ratings/helpers/ratingsPageData';
import recentItemsLoadData from './pages/RecentItems/helpers/recentItemsPageData';
import recommendedItemLoadData from './pages/RecommendedItem/helpers/recommendedItemPageData';
import reviewLoadData from './pages/Review/helpers/reviewPageData';
import searchLoadData from './pages/Search/helpers/searchPageData';
import sellerLoadData from './pages/Seller/helpers/sellerPageData';
import storefrontLoadData from './pages/Storefront/helpers/storeFrontPageData';
import wonItemsLoadData from './pages/WonItems/helpers/wonItemsPageData';

/**
 * Async codesplitting at the route level with @loadable-components
 * @see https://loadable-components.com/docs/dynamic-import/
 */
const ACHTermsAndConditions = loadable(() => import('./pages/TermsAndConditions/ACHTermsAndConditionsPage'));
const AboutUs = loadable(() => import('./pages/AboutUs/AboutUsPage'));
const AccountSettings = loadable(() => import('./pages/AccountSettings/AccountSettingsPageContainer'));
const AddCreditCard = loadable(() => import('./pages/AddCreditCard/AddCreditCardPage'));
const AuctionCalendar = loadable(() => import('./pages/AuctionCalendar/AuctionCalendarPageContainer'));
const AuctionResults = loadable(() => import('./pages/AuctionResults/AuctionResultsPage'));
const AuctionsNearMe = loadable(() => import('./pages/AuctionsNearMe/AuctionsNearMePage'));
const AuctionVideo = loadable(() => import('./pages/AuctionVideo/AuctionVideoPage'));
const BiddingConsole = loadable(() => import('./pages/BiddingConsole/BiddingConsolePage'));
const Blocked = loadable(() => import('./pages/Blocked/BlockedByWaf'));
const AuthSSORedirect = loadable(() => import('./pages/AuthSSORedirectPage/AuthSSORedirectPage'));
const Catalog = loadable(() => import('./pages/Catalog/CatalogPage'));
const CatalogTerms = loadable(() => import('./pages/CatalogTerms/CatalogTermsPage'));
const Category = loadable(() => import('./pages/Search/CategorySearch/CategorySearchPageContainer'));
const Checkout = loadable(() => import('./pages/Checkout/CheckoutPage'));
const CheckoutATG = loadable(() => import('./pages/CheckoutATG/CheckoutPageATG'));
const Collection = loadable(() => import('./pages/CuratedCollection/CuratedCollectionPage'));
const Consignments = loadable(() => import('./pages/Consignments/ConsignmentsPageContainer'));
const CookiePolicy = loadable(() => import('./pages/Cookie/CookiePolicyPage'));
const DeleteAccount = loadable(() => import('./pages/DeleteAccount/DeleteAccountPage'));
const Directory = loadable(() => import('./pages/SellerDirectory/SellerDirectoryPage'));
const Discover = loadable(() => import('./pages/Discover/DiscoverPage'));
const DiscoverCategories = loadable(() => import('./pages/DiscoverCategories/DiscoverCategories'));
const Features = loadable(() => import('./pages/Features/FeaturesPage'));
const FeaturedCatalogs = loadable(() => import('./pages/FeaturedCatalogs/FeaturedCatalogsPage'));
const ForYou = loadable(() => import('./pages/ForYou/ForYouPageContainer/ForYouPageContainer'));
const Home = loadable(() => import('./pages/Home/HomePage'));
const HowAuctionsWork = loadable(() => import('./pages/HowAuctionsWork/HowAuctionsWorkPage'));
const IntellectualProperty = loadable(() => import('./pages/IntellectualProperty/IntellectualPropertyPage'));
const Item = loadable(() => import('./pages/Item/ItemPage/ItemPage'));
const ImageCarouselPopoutPage = loadable(() => import('./pages/ImageCarouselPopout/ImageCarouselPopoutPage'));
const LiveAuctioneerCapital = loadable(() => import('./pages/LiveAuctioneersCapital/LiveAuctioneersCapital'));
const Messages = loadable(() => import('./pages/Messages/MessagesPage'));
const Messenger = loadable(() => import('./pages/Messages/MessengerPage'));
const MyAuctions = loadable(() => import('./pages/MyAuctions/MyAuctionsPageContainer'));
const NotFound = loadable(() => import('./pages/NotFound/NotFound'));
const Notifications = loadable(() => import('./pages/Notifications/NotificationsPage'));
const PRServices = loadable(() => import('./pages/PRServices/PRServicesPage'));
const PasswordReset = loadable(() => import('./pages/PasswordReset/PasswordResetPageContainer'));
const PriceResults = loadable(() => import('./pages/Item/PriceResultsPage/PriceResultsPage'));
const Privacy = loadable(() => import('./pages/Privacy/PrivacyPage'));
const Ratings = loadable(() => import('./pages/Ratings/RatingsPageContainer'));
const RecentlyViewed = loadable(() => import('./pages/RecentItems/RecentItemsPage'));
const RecommendedItem = loadable(() => import('./pages/RecommendedItem/RecommendedItemPage'));
const Review = loadable(() => import('./pages/Review/ReviewPageContainer'));
const MyBids = loadable(() => import('./pages/MyItems/MyBids/MyBidsPage'));
const MyFavorites = loadable(() => import('./pages/MyItems/MyFavorites/MyFavoritesPage'));
const Search = loadable(() => import('./pages/Search/KeywordSearch/SearchPageContainer'));
const SearchPageSimplified = loadable(() => import('./pages/SearchPageSimplified/SearchPageSimplified'));
const Seller = loadable(() => import('./pages/Seller/SellerPageContainer'));
const SellerReviews = loadable(() => import('./pages/SellerReviews/SellerReviewsPage'));
const ShippingPolicy = loadable(
    () => import('@/components/PartnerPolicies/components/ShippingPolicyPage/ShippingPolicyPage')
);
const Storefront = loadable(() => import('./pages/Storefront/StorefrontPageContainer'));
const TaxCertCapture = loadable(() => import('./pages/TaxCertCapture/TaxCertCapturePage'));
const Terminology = loadable(() => import('./pages/Terminology/TerminologyPage'));
const TermsAndConditions = loadable(() => import('./pages/TermsAndConditions/TermsAndConditionsPage'));
const TermsAndConditionsPayments = loadable(() => import('./pages/TermsAndConditions/TermsAndConditionsPaymentsPage'));
const WonItems = loadable(() => import('./pages/WonItems/WonItemsPageContainer'));
const LoginRedirect = loadable(() => import('./pages/LoginRedirect/LoginRedirectPage'));

export type LoadableRouteConfig = RouteObject & {
    is404?: boolean;
    loadData?: (args: DataLoaderParams) => void | Promise<any>;
};

export interface RoutesParams {
    featureFlagVariants: { [key: string]: string | undefined };
    is404ItemPage?: boolean;
    isWhiteLabel?: boolean;
}

/**
 * Each route can optionally have an async loadData function to fetch data during SSR
 */
export const routes = ({ is404ItemPage, isWhiteLabel }: RoutesParams): LoadableRouteConfig[] => [
    // https://localdev.liveauctioneers.com:3443/
    {
        ...(isWhiteLabel
            ? { Component: Seller, loadData: sellerLoadData }
            : { Component: Home, loadData: homeLoadData }),
        path: '/',
    },
    // https://localdev.liveauctioneers.com:3443/delete-account/:bidderId
    {
        Component: DeleteAccount,
        path: '/delete-account/:bidderId',
    },
    // https://localdev.liveauctioneers.com:3443/about
    { Component: AboutUs, loadData: aboutUsLoadData, path: '/about' },
    // https://localdev.liveauctioneers.com:3443/auction-results
    { Component: AuctionResults, path: '/auction-results' },
    // Handle Blocked by Waf
    { Component: Blocked, path: '/blocked' },
    // https://localdev.liveauctioneers.com:3443/auctioneer/directory
    {
        Component: Directory,
        loadData: directoryLoadData,
        path: '/auctioneer/directory',
    },
    // https://localdev.liveauctioneers.com:3443/auctioneer/6341/cloudswood-auction
    {
        Component: Seller,
        loadData: sellerLoadData,
        path: '/auctioneer/:sellerId/:sellerName',
    },
    // https://localdev.liveauctioneers.com:3443/auctioneer/6341/cloudswood-auction
    {
        Component: AuthSSORedirect,
        path: '/auth/google',
    },
    {
        Component: AuthSSORedirect,
        path: '/auth/facebook',
    },
    // https://localdev.liveauctioneers.com:3443/auctioneer/6341/cloudswood-auction/reviews
    {
        Component: Ratings,
        loadData: ratingsLoadData,
        path: '/auctioneer/:sellerId/:sellerName/reviews',
    },
    // https://localdev.liveauctioneers.com:3443/shipping-policies/3/thepackengers
    {
        Component: ShippingPolicy,
        path: '/shipping-policies/:partnerId/:partnerName',
    },
    // https://localdev.liveauctioneers.com:3443/seller/6341
    {
        Component: Storefront,
        loadData: storefrontLoadData,
        path: '/seller/:sellerId',
    },
    // https://localdev.liveauctioneers.com:3443/c/
    {
        Component: DiscoverCategories,
        loadData: discoverCategoriesLoadData,
        path: '/c',
    },
    // https://localdev.liveauctioneers.com:3443/c/drawings/8/
    {
        Component: Category,
        loadData: categoryLoadData,
        path: '/c/:categoryName/:categoryId',
    },
    // https://localdev.liveauctioneers.com:3443/c/art/pablo-picasso/1/18755/
    {
        Component: Category,
        loadData: categoryLoadData,
        path: '/c/:categoryName/:categoryName2/:categoryId/:categoryId2',
    },
    // skip this similar-items route if using fallback
    // https://localdev.liveauctioneers.com:3443/similar-items/art/pablo-picasso/1/18755/
    {
        Component: Category,
        loadData: categoryLoadData,
        path: '/similar-items/:categoryName/:categoryName2/:categoryId/:categoryId2',
    },
    {
        Component: BiddingConsole,
        loadData: biddingConsoleLoadData,
        path: '/console/:catalogId',
    },
    // https://localdev.liveauctioneers.com:3443/similar-items/drawings/8/
    // skip this similar-items route if using fallback
    {
        Component: Category,
        loadData: categoryLoadData,
        path: '/similar-items/:categoryName/:categoryId',
    },
    // route to the Bid Console's ImageCarousel Popout
    {
        Component: ImageCarouselPopoutPage,
        loadData: imageCarouselPopoutPageData,
        path: '/console/:catalogId/:itemId/images/:imageNum',
    },
    // https://localdev.liveauctioneers.com:3443/featured/extraordinary-treasures/26
    {
        Component: Collection,
        loadData: collectionLoadData,
        path: '/featured/:collectionName/:collectionId',
    },
    // https://localdev.liveauctioneers.com:3443/price-guide
    { Component: AuctionResults, path: '/price-guide' },
    // https://localdev.liveauctioneers.com:3443/price-guide/drawings/8/
    {
        Component: Category,
        loadData: categoryLoadData,
        path: '/price-guide/:categoryName/:categoryId',
    },
    // https://localdev.liveauctioneers.com:3443/catalog/search
    {
        Component: AuctionCalendar,
        loadData: auctionCalendarLoadData,
        path: '/catalog/search',
    },
    // https://localdev.liveauctioneers.com:3443/catalog/catalogId
    {
        Component: Catalog,
        loadData: catalogLoadData,
        path: '/catalog/:catalogId',
    },
    // https://localdev.liveauctioneers.com:3443/video/catalogId
    {
        Component: AuctionVideo,
        loadData: auctionVideoLoadData,
        path: '/video/:catalogId',
    },
    // https://localdev.liveauctioneers.com:3443/consignments
    {
        Component: Consignments,
        loadData: consignmentsLoadData,
        path: '/consignments',
    },
    // https://localdev.liveauctioneers.com:3443/consignments/6341
    {
        Component: Consignments,
        loadData: consignmentsLoadData,
        path: '/consignments/:sellerId',
    },
    // https://localdev.liveauctioneers.com:3443/cookie-policy
    { Component: CookiePolicy, path: '/cookie-policy' },
    // https://localdev.liveauctioneers.com:3443/features
    {
        Component: Discover,
        loadData: discoverLoadData,
        path: '/discover/:topic',
    },
    // https://localdev.liveauctioneers.com:3443/features
    { Component: Features, path: '/Features' },
    // https://localdev.liveauctioneers.com:3443/find-auctions-near-me
    {
        Component: FeaturedCatalogs,
        loadData: featuredCatalogsLoadData,
        path: '/featured-auctions',
    },
    // https://localdev.liveauctioneers.com:3443/find-auctions-near-me/
    {
        Component: AuctionsNearMe,
        loadData: auctionsNearMePageData,
        path: '/find-auctions-near-me/',
    },
    // https://localdev.liveauctioneers.com:3443/find-auctions-near-me/20/denver/
    {
        Component: AuctionsNearMe,
        loadData: auctionsNearMePageData,
        path: '/find-auctions-near-me/:geoAreaId/:geoAreaName/',
    },
    // https://localdev.liveauctioneers.com:3443/how-auctions-work
    { Component: HowAuctionsWork, path: '/how-auctions-work' },
    // https://localdev.liveauctioneers.com:3443/item/78040263_georgian-banded-mahogany-cellarette
    {
        ...(is404ItemPage ? { Component: NotFound, is404: true } : { Component: Item, loadData: itemLoadData }),
        path: '/item/:itemId',
    },
    // { Component: Item, loadData: itemLoadData, path: '/item/:itemId' },
    // https://localdev.liveauctioneers.com:3443/capital
    { Component: LiveAuctioneerCapital, path: '/capital' },
    // https://localdev.liveauctioneers.com:3443/password_reset
    { Component: PasswordReset, path: '/password_reset' },
    {
        Component: PasswordReset,
        path: '/password_reset/:bidderId/:hash',
    },
    // https://localdev.liveauctioneers.com:3443/prservices
    { Component: PRServices, path: '/prservices' },
    // https://localdev.liveauctioneers.com:3443/price-result/pre-owned-submariner
    {
        ...(is404ItemPage
            ? { Component: NotFound, is404: true }
            : { Component: PriceResults, loadData: priceResultLoadData }),
        path: '/price-result/:slugId',
    },
    // https://localdev.liveauctioneers.com:3443/privacy
    { Component: Privacy, path: '/privacy' },
    // https://localdev.liveauctioneers.com:3443/recent
    {
        Component: RecentlyViewed,
        loadData: recentItemsLoadData,
        path: '/recent',
    },

    // https://localdev.liveauctioneers.com:3443/search
    {
        Component: SearchPageSimplified,
        loadData: searchLoadData,
        path: '/search',
    },
    { Component: Search, loadData: searchLoadData, path: '/search' },

    // https://localdev.liveauctioneers.com:3443/similar-items
    // only use if using fallback
    { Component: Search, loadData: searchLoadData, path: '/similar-items' },
    // https://localdev.liveauctioneers.com:3443/seller-reviews
    { Component: SellerReviews, path: '/seller-reviews' },
    // https://localdev.liveauctioneers.com:3443/termsandconditionspayments
    {
        Component: TermsAndConditionsPayments,
        path: '/termsandconditionspayments',
    },
    // https://localdev.liveauctioneers.com:3443/achtermsandconditions
    { Component: ACHTermsAndConditions, path: '/achtermsandconditions' },
    // https://localdev.liveauctioneers.com:3443/termsandconditions
    { Component: TermsAndConditions, path: '/termsandconditions' },
    // https://localdev.liveauctioneers.com:3443/terms/154704_chinese-ceramics-and-works-of-art/
    {
        Component: CatalogTerms,
        loadData: catalogTermsLoadData,
        path: '/terms/:catalogId',
    },
    // https://localdev.liveauctioneers.com:3443/terminology
    { Component: Terminology, path: '/terminology' },
    // https://localdev.liveauctioneers.com:3443/intellectualproperty
    {
        Component: IntellectualProperty,
        path: '/intellectualproperty',
    },
    /**
     * Authenticated routes
     */
    {
        Component: AccountSettings,
        loadData: accountSettingsPageData,
        path: '/dashboard/profile',
    },
    { Component: AddCreditCard, path: '/dashboard/cardonfile' },
    {
        Component: ForYou,
        path: '/dashboard/followed-auctioneers-for-you',
    },
    // https://localdev.liveauctioneers.com:3443/dashboard/messages
    {
        Component: Messages,
        loadData: messagesPageData,
        path: '/dashboard/messages',
    },
    {
        Component: Messenger,
        loadData: messengerPageData,
        path: '/dashboard/messages/:conversationId',
    },
    // https://localdev.liveauctioneers.com:3443/dashboard/my-bids
    { Component: MyBids, loadData: myBidsLoadData, path: '/dashboard/my-bids' },
    // https://localdev.liveauctioneers.com:3443/dashboard/saved-items
    {
        Component: MyFavorites,
        loadData: myFavoritesLoadData,
        path: '/dashboard/saved-items',
    },
    // https://localdev.liveauctioneers.com:3443/dashboard/my-auctions
    { Component: MyAuctions, path: '/dashboard/my-auctions' },
    // https://localdev.liveauctioneers.com:3443/dashboard/emails-and-notifications
    {
        Component: Notifications,
        path: '/dashboard/emails-and-notifications',
    },
    // https://localdev.liveauctioneers.com:3443/dashboard/followed-searches
    {
        Component: ForYou,
        path: '/dashboard/followed-searches',
    },
    // https://localdev.liveauctioneers.com:3443/dashboard/for-you
    {
        Component: ForYou,
        path: '/dashboard/for-you',
    },
    { Component: Review, loadData: reviewLoadData, path: '/review/:catalogId' },
    { Component: Checkout, path: '/checkout/:catalogId' },
    { Component: CheckoutATG, path: '/checkoutATG/:externalId' },
    // https://localdev.liveauctioneers.com:3443/dashboard/won-items
    {
        Component: WonItems,
        loadData: wonItemsLoadData,
        path: '/dashboard/won-items',
    },
    // https://localdev.liveauctioneers.com:3443/recommended
    {
        Component: RecommendedItem,
        loadData: recommendedItemLoadData,
        path: '/recommended',
    },
    {
        Component: RecommendedItem,
        loadData: recommendedItemLoadData,
        path: '/recommended/:amazon',
    },
    { Component: TaxCertCapture, path: '/certcapture' },
    { Component: LoginRedirect, path: '/loginRedirect' },
    /**
     * Handle 404 pages
     */
    { Component: NotFound, is404: true, path: '/not-found' },
    { Component: NotFound, is404: true, path: '*' },
];

const AppRoutes = ({
    featureFlagVariants,
    is404ItemPage,
    location,
    whiteLabel,
}: {
    featureFlagVariants: { [key: string]: string | undefined };
    is404ItemPage?: boolean;
    location?: Location;
    whiteLabel: boolean;
}) => useRoutes(routes({ featureFlagVariants, is404ItemPage, isWhiteLabel: whiteLabel }), location);

export default AppRoutes;
